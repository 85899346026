const settings = {
    state: () => ({
        bus: [
            {
                n: 13,
                d: 'Izza mavzeysi - Toshkent MUM',
                d_ru: "Массив Изза - Ташкентский ЦУМ",
                t: '5:00 - 21:00',
                m:'Milliy bog, Xalqlar do`stligi',
                m_ru:'Миллий бог, Дружба Народов',
                yandex:'https://yandex.uz/maps/10335/tashkent/routes/bus_13/796d617073626d313a2f2f7472616e7369742f6c696e653f69643d31373034383532343332266c6c3d36392e32313032363725324334312e323834373639266e616d653d313326723d3536343426747970653d627573/?l=masstransit&ll=69.222151%2C41.297609&tab=stops&z=14.41'
            },
            {
                n: 48,
                d: 'Al-Xorazmiy mavzeysi - Navotor IIB',
                d_ru: "Массив Аль-Хорезми - Навотор МИБ",
                t: '6:30 - 21:50',
                m: 'Chilonzor',
                m_ru: 'Чиланзар',
                yandex: 'https://yandex.uz/maps/10335/tashkent/routes/bus_48/796d617073626d313a2f2f7472616e7369742f6c696e653f69643d31383938313031303131266c6c3d36392e31393339363225324334312e323233383339266e616d653d343826723d3535373326747970653d627573/?l=masstransit&ll=69.237627%2C41.186051&tab=timetable&z=16'
            },
            {
                n: 69,
                d: 'Karvon bozori - Toshkent vokzali',
                d_ru: "Караванный рынок - Железнодорожный вокзал Ташкента",
                t: '5:30 - 21:20',
                m: 'Chilonzor',
                m_ru: 'Чиланзар',
                yandex: 'https://yandex.uz/maps/10335/tashkent/routes/bus_69/796d617073626d313a2f2f7472616e7369742f6c696e653f69643d33343539373136383435266c6c3d36392e32313634323525324334312e323738323631266e616d653d363926723d3539353026747970653d627573/?l=masstransit&ll=69.207787%2C41.272772&tab=stops&z=17'
            },
            {
                n: 87,
                d: 'Chinni bozori - Qoraqamish 2/4 mavzesi',
                d_ru: "Чинни базар - Каракамыш 2/4",
                t: '5:30 - 21:50',
                m:'Beruniy',
                m_ru:'Беруний',
                yandex:'https://yandex.uz/maps/10335/tashkent/routes/bus_87/796d617073626d313a2f2f7472616e7369742f6c696e653f69643d31373034383932313636266c6c3d36392e31383236343525324334312e333135373531266e616d653d383726723d3636313726747970653d627573/?l=masstransit&ll=69.163639%2C41.262871&tab=stops&z=14.78'
            },
            {
                n: 94,
                d: 'Izza mavzesi - Xalqaro aeroport-2',
                d_ru: "Массив Изза - Международный аэропорт-2",
                t: '5:30 - 21:30',
                m: 'M.Ulug`bek',
                m_ru: 'М.Улугбек',
                yandex: 'https://yandex.uz/maps/10335/tashkent/routes/bus_94/796d617073626d313a2f2f7472616e7369742f6c696e653f69643d31373034383933373834266c6c3d36392e32303735303825324334312e323736363538266e616d653d393426723d3530363126747970653d627573/?l=masstransit&ll=69.183157%2C41.270013&tab=stops&z=15'
            },
            {
                n: 146,
                d: 'Chinni bozori - Chorsu savdo markazi',
                d_ru: "Чинни базар - торговый центр Чорсу",
                t: '6:00 - 19:20',
                m:'Chorsu',
                m_ru:'Чарсу',
                yandex:'https://yandex.uz/maps/10335/tashkent/routes/bus_146/796d617073626d313a2f2f7472616e7369742f6c696e653f69643d31393538363633313735266c6c3d36392e32303338343325324334312e323832303832266e616d653d31343626723d3534373526747970653d627573/?l=masstransit&ll=69.166799%2C41.260963&tab=stops&z=19'
            },
            
            
        ],
        minibus: [
            {
                n: 36,
                d: 'Algoritim 31-mavzesi-Jangoh mahalla',
                d_ru: "Массив Алгоритм 31-Джангох Махалля",
                t: '6:30 - 20:40'
            },
            {
                n: 46,
                d: 'Chilonzor-S mavzesi- O`rikzor bozori',
                d_ru: "Массив Чиланзар-5 рынок Урикзар",
                t: '6:30 - 20:20'
            },
            {
                n: 170,
                d: 'O`rikzor bozori- Qo`yliq dehqon bozori',
                d_ru: "Рынок Урикзар - Рынок Куйлюк",
                t: '6:30 - 19:20'
            }
        ]
    }),
    getters:{
        bus(state){
            return state.bus
        },
        minibus(state){
            return state.minibus
        }
    }
}

export default settings